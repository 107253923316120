@keyframes err{
    0%,
    100%{
      transform: translateX(0);
    }
  
    12.5%{
      transform: translateX(12px);
    }
  
    25%{
      transform: translateX(-12px);
    }
  
    37.5%{
      transform: translateX(8px);
    }
  
    50%{
      transform: translateX(-8px);
    }
  
    62.5%{
      transform: translateX(5px);
    }
  
    75%{
      transform: translateX(-5px);
    }
  
    87.5%{
      transform: translateX(3px);
    }
  }

.header{position: fixed;top: 0;left: 0;width: 100%;background: #fff;z-index: 9000}
.headerSection{height: 54px;display: flex;align-items: center;justify-content: center;padding: 0 20px}
.btnBack{position: absolute;top: 50%;left: 20px;transform: translateY(-50%)}
.btnHeaderRight{position: absolute;right: 20px;top: 50%;transform: translateY(-50%)}
.headTitle{font-weight: 700;position: absolute;top: 50%;left: 20px;transform: translateY(-50%)}
.headTitle.centerTitle{position: static;font-size: 14px;font-weight: 500;transform: translateY(0);text-align: center;max-width: calc(100% - 78px);overflow: hidden;text-overflow: ellipsis;white-space: nowrap}
.btnSetting{color: #838383;font-weight: 400;font-size: 14px;text-decoration-line: underline;text-align: right}

.btnTopCenter{display: flex;flex-direction: column;justify-content: flex-start}
.btnCenter{align-items: center}

.btn_headerInfoText{position: absolute;top: 41px;right: -8px;display: none}
.btn_headerInfoText span{display: block;position: relative;background: rgba(94, 94, 94, 0.8);border-radius: 9px;font-size: 13px;padding: 7px 10px;line-height: 1.4;color: #fff}
.btn_headerInfoText span::after{content: "";height: 0;width: 0;position: absolute;border: solid transparent;border-bottom-color: rgba(94, 94, 94, 0.8);border-width: 7px;pointer-events: none;bottom: 100%;right: 13px}
.btn_headerInfoText span i{display: block;font-style: normal;white-space: nowrap}

.headerTextBtn{font-size: 14px;color: #838383;text-decoration-line: underline;text-align: right}

.memberPage{padding: 54px 20px 120px}
.memberTitle{font-size: 22px;font-weight: 500}
.memberSubTitle{margin: 10px 0 0;font-size: 14px;color: #5e5e5e}
.memberInput_section{margin: 30px 0 0}
.pageBtn_box{padding: 15px 20px;position: fixed;bottom: 0;left: 0;width: 100%;background-color: #fff;z-index: 1001}
.pageBtn{border-radius: 15px;display: flex;align-items: center;justify-content: center;width: 100%;height: 48px;background: #3A3A3A;font-weight: 500;font-size: 15px;color: #fff;border: 1px solid #3A3A3A}
.pageBtn.wType{color: #1E1E1E;border-color: #C7C7C7;background: #fff}
.greenBtn .pageBtn{background: #F87A1C;border-color: #F87A1C}
.pageBtn_box.col2{display: flex;justify-content: space-between}
.pageBtn.greenBtn{background: #F87A1C;border-color: #F87A1C}
.pageBtn_box.col2 .pageBtn{width: calc(50% - 5px);margin-top: 0}
.pageBtn:disabled{background: #d8d8d8;border-color: #d8d8d8}

.horizontalScroll{display: flex;width: 100%;overflow-x: auto}
.fixedItem{position: fixed;left: 0;top: 54px;width: 100%;z-index: 9000;background: #fff;padding: 12px 20px}

.topTap{position: fixed;top:54px;left: 0;width:100%;background: #fff;z-index: 1000}
.bottomBtnPage{padding-bottom: 78px}

/*onboarding*/
.onboardingPage{padding: 54px 20px 78px}
.onboardingItem{display: flex;flex-direction: column;justify-content: space-between;align-items: center;text-align: center;min-height: calc(100vh - 54px - 78px);overflow: hidden}
.onboarding_titleBox{padding: 0 0 20px}
.onboarding_title{font-weight: 700;font-size: 18px;color: #1E1E1E}
.onboarding_text{color: #838383;font-size: 13px;margin: 6px auto 0;max-width: 300px;line-height: 20.8px}
.onboardingItem img{width: 100%;min-width: 220px;max-width: 330px;transform: translateY(93px)}

/*footer*/
.footerMenu{position: fixed;bottom: 0;left: 0;width: 100%;display: flex;align-items: center;justify-content: space-between;padding: 6px 8px;z-index: 1000;background: #fff}
/* .footerMenuItem{padding: 6px 0;color: #ACACAC;font-size: 9px;font-weight: 500;text-align: center;width: calc(20% - 12.8px);border-radius: 15px} */
.footerMenuItem{padding: 6px 0;color: #ACACAC;font-size: 9px;font-weight: 500;text-align: center;width: calc(25% - 12px);border-radius: 15px}
.footerMenuItem + .footerMenuItem{margin: 0 0 0 16px}
.footerMenuItem.active{background: #F6F6F6;color: #1E1E1E}
.footerMenuItem > img{margin: 0 auto 4px}

.footer{background: #F6F6F6;margin: 40px 0 0}
.footerSection{padding: 25px 20px}
.footer_title{color: #5E5E5E;font-weight: 700;font-size: 14px;display: flex;align-items: center}
.footerSection.active .footer_title>img{transform: rotate(180deg)}
.footer_text{font-size: 11px;color: #838383;margin: 9px 0 0}
.footer_infoArea{margin: 15px 0 0}
.footer_infoText{color: #838383;font-size: 11px}
.footer_infoText + .footer_infoText{margin: 6px 0 0}
.footer_infoText span{font-weight: 500;margin: 0 4px 0 0}
.footer_menuBox{margin: 16px 0 0;padding: 10px 0 0;border-top: 1px solid #E7E7E7;display: flex}
.footer_title{color: #838383;font-weight: 700;font-size: 12px}
.footer_title + .footer_title{margin: 0 0 0 10px}  

/*input*/
.inputName{font-size: 12px;color: #5e5e5e;font-weight: 500;margin: 0 0 4px}
.inputItem{border: 0;border-bottom: 1px solid #E7E7E7;width: 100%;padding: 10px 6px;font-size: 14px}
.inputItemBox + .inputItemBox{margin: 21px 0 0}
.inputItem:disabled{background-color:rgba(231, 231, 231, 0.5);color: #acacac;-webkit-text-fill-color: #acacac}

.reviewWriting_textAreaBox{position: relative}
.textareaItem{display: block;border: 0;border-bottom: 1px solid #E7E7E7;width: 100%;padding: 10px 6px;font-size: 14px;resize: none;min-height: 100px}
.textareaItem:disabled{background-color:rgba(231, 231, 231, 0.5);color: #acacac;-webkit-text-fill-color: #acacac}
.textareaCount{font-weight: 500;font-size: 12px;position: absolute;width: 100%;text-align: right;bottom: 0;right: 0;padding: 0 10px 10px;background: #fff;border-radius: 7px;color: #C7C7C7}

.inputBox.type2 .textareaItem,.inputBox.type2 .inputItem{padding: 10px;border: 1px solid #E7E7E7;border-radius: 7px}
.inputBox.type2 .inputItem.customSel{background-position: right 10px center}
.inputFileBox label{background-repeat: no-repeat;background-size: cover;background-position: center;background-color: #f6f6f6;border-radius: 25px;overflow: hidden;position: relative;display: block;width: 100%;padding: 0 0 100%}
.inputFileBox label.btn_imageSel{padding: 0;width: 70px;border-radius: 15px;display: flex;flex-direction: column;color: #ACACAC;font-size: 11px;font-weight: 500;line-height: 1;gap: 2px;cursor: pointer}
.inputFile_label_basic{display: flex;position: absolute;top: 0;left: 0;width: 100%;height: 100%;align-items: center;justify-content: center;flex-direction: column}
.inputFile_label_text{text-align: center;font-weight: 500;font-size: 14px;color: #838383;margin: 15px 0 0}
.delImage{position: absolute;top: 14px;right: 14px}
.fileImage_item{background-repeat: no-repeat;background-size: cover;background-position: center;background-color: #f6f6f6;border-radius: 25px;overflow: hidden;position: relative;display: block;width: 100%;padding: 0 0 100%}

.inputBox .maxChk{padding: 10px 10px 39px;border: 1px solid #E7E7E7;border-radius: 7px}
.inputBox .maxChk .textareaItem{border: 0;padding: 0}

.inputFileList{width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex}
.inputFileBox .inputFileList label{min-width: 70px;width: 70px;height: 70px;padding: 0;border: 1px solid #E7E7E7;border-radius: 15px;display: flex;align-items: center;justify-content: center}
.fileItemMultipleItem{background-repeat: no-repeat;background-size: cover;background-position: center;width: 70px;height: 70px;min-width: 70px;border-radius: 15px;margin: 0 0 0 12px;position: relative}
.fileItemMultipleItemDel{position: absolute;top:5px;right:5px;z-index: 1}

.chk_item{padding: 15px;display: flex;align-items: center;justify-content: space-between}
.chk_item.allChkItem{background: #F6F6F6;border-radius: 15px}
.chk_item label{position: relative;padding: 0 0 0 33px;width: calc(100% - 34px)}
.chk_item label::before{content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 22px;height: 22px;background: url(/assets/images/basic/check_off.svg) no-repeat center;background-size: 100%}    
.chk_item input:checked + label::before{background-image: url(/assets/images/basic/check_on.svg)}
.chk_item_text{color: #5E5E5E;font-size: 14px}
.chk_item>img{margin: 0 0 0 10px}
.btnChk_area .chk_item{padding: 15px 0}

.inputItem.customSel{background: url(/assets/images/basic/sel.svg) no-repeat right center;padding-right: 34px}
.registrationNumberBox{display: flex;align-items: center;justify-content: space-between}
.registrationNumberBox > .inputItem{width: calc(50% - 12px)}
.registrationNumberBox > span{display: block;width: 24px;text-align: center;font-weight: 700;font-size: 14px}
.registrationNumberBackBox{display: flex;align-items: center;justify-content: space-between;width: calc(50% - 12px)}
.registrationNumberBackBox .inputItem{width: 30px;min-width: 30px;text-align: center;padding-left: 0;padding-right: 0}
.registrationNumberBack_text{font-weight: 700;width: calc(100% - 30px);letter-spacing: 3px}

.toggleBox label{display: flex;font-weight: 500;align-items: center;justify-content: space-between}
.toggleBox label span{background: #ACACAC;border-radius: 23px;width: 35px;height: 20px;position: relative;transition: background 0.3s}
.toggleBox label span::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);left: 3px;background: #fff;border-radius: 100%;width: 14px;height: 14px;box-shadow: 0px 0px 2px #9A9A9A;transition: left 0.3s cubic-bezier(0.89, 0.11, 0.09, 0.89)}
.toggleBox .toggleChk:checked + label span{background: #F87A1C}
.toggleBox .toggleChk:checked + label span::before{left: calc(100% - 17px)}

/*popup*/
.popup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.popup_cover{width: 100%;height: 100%;background: rgba(0, 0, 0, 0.6);opacity: 0;transition: opacity 0.2s}
.popupSetion{background: #fff;position: absolute;bottom: 0;left: 0;z-index: 1;border-top-left-radius: 10px;border-top-right-radius: 10px;padding: 20px 20px 16px;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.popup.active .popup_cover{opacity: 1}
.popup.active .popupSetion{transform: translateY(0)}
.popupTitle{font-weight: 500;font-size: 18px;text-align: left}
.popupText{font-size: 14px;margin: 8px 0 0;color: #838383}
.popup_btnBox{margin: 26px 0 0;display: flex;align-items: center;justify-content: space-between}
.btn_popup{background: #000;border-radius: 9px;font-weight: 500;font-size: 15px;color: #fff;padding: 14.5px;width: 100%}
.btn_popup.notColor{background: #fff;border: 1px solid #000;color: #000;width: calc(50% - 4px)}
.btn_popup.notColor + .btn_popup{width: calc(50% - 4px)}
.popupTextCaption{font-size: 12px;color: rgba(172, 172, 172, 1)}
.btn_selPopupClose{position: absolute;right: 20px;top: 20px}
.slideUpPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.slideUpPopup.active .popup_cover{opacity: 1}
.slideUpPopup.active .popupSetion{transform: translateY(0)}

.popup_btnBox.col2 .btn_popupLeft{background: #fff;border: 1px solid #C7C7C7;color: #000;width: calc(50% - 4px)}
.popup_btnBox.col2 .btn_popupRight{width: calc(50% - 4px)}

/*selPopup*/
.selPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.selPopupSetion{border-top-left-radius: 24px;border-top-right-radius: 24px;padding: 24px 0 0;background: #fff;position: absolute;bottom: 0;left: 0;z-index: 1;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.selPopupTextBox{padding: 0 20px 10px}
.selPopup_selBox{padding: 20px;max-height: 300px;overflow-y: auto}
.selPopup_sel{padding: 15px;text-align: center;border-radius: 15px;border: 0;display: block;width: 100%;font-size: 14px;font-weight: 400}
.selPopup.active .popup_cover{opacity: 1}
.selPopup.active .selPopupSetion{transform: translateY(0)}
.selPopup_sel.active{font-weight: 500;background-color: #f6f6f6}
.certificationCount{color: #00A3FF;font-size: 14px;position: absolute;right: 0;top: 50%;transform: translateY(-50%)}
.errMsg{margin: 4px 0 0;font-weight: 500;color: #FF9797;font-size: 12px}
.err .inputItem{animation: err 0.3s;border-color: #ff3344}
.btnCom{display: flex;justify-content: space-between}
.certificationInput{width: calc(100% - 90px);position: relative}
.btn_inputSubBtn{border: 1px solid #C7C7C7;border-radius: 15px;font-weight: 500;font-size: 14px;min-width: 80px}

/*rightPopup*/
.rightPopupClose{position: absolute;top: 50%;right: 20px;transform: translateY(-50%)}
.rightPopup{background: #fff;position: fixed;top: 0;right: 0;width: 100%;height: 100%;transform: translateX(100%);z-index: 9000;transition: transform 0.2s cubic-bezier(0.89, 0.11, 0.09, 0.89)}
.rightPopup.active{transform: translateX(0)}
.rightPopup_header_section{height: 54px;position: relative;padding: 0 20px;display: flex;align-items: center}
.rightPopup_header_title{font-weight: 500;font-size: 14px;text-align: center;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);padding: 0 10px;}
.rightPopup_contents{height: calc(100% - 54px);overflow-y: auto;padding: 0}
.rightPopup_title_area{padding: 0 20px;margin: 0 0 30px}
.rightPopup_title{font-size: 22px;font-weight: 500}
.terms_text{font-size: 12px;color: #707070;padding: 0 20px 50px}
.rightPopup.bPopup{background: rgba(53, 53, 53, 0.9);backdrop-filter: blur(5.5px);-webkit-backdrop-filter: blur(5.5px)}
.termsIframe{min-height: calc(100vh - 115px)}

/*bottomErrMsg*/
.bottomErrMsg{text-align: center;padding: 10px;color: #fff;font-size: 13px;font-weight: 500;opacity: 0;background: rgba(94, 94, 94, 0.8);border-radius: 10px;position: fixed;bottom: 81px;left: 50%;transform: translateX(-50%);width: calc(100% - 40px);display: none;transition: opacity 0.2s;z-index: 9999}
.bottomErrMsg.active{opacity: 1}

/*main*/
.mainPage{padding: 54px 0 66px}
.addrSelBox{border: 1px solid #E7E7E7;border-radius: 49px;background: #F6F6F6;padding: 4px 10px;display: flex;align-items: center;justify-content: space-between;font-weight: 500;font-size: 14px;position: absolute;left: 20px;top: 50%;transform: translateY(-50%)}

/*cafe*/
.banner_section .swiper{padding: 0 20px}
.bannerImg{width:100%;background-repeat: no-repeat;background-position: center;background-size: cover;background-color: #f1f1f1;padding: 0 0 93.75%;border-radius: 15px;display: block}
.banner_section .swiper-pagination{position: absolute;right: 32px;bottom: 12px;z-index: 1;background: rgba(0, 0, 0, 0.4);border-radius: 33px;font-weight: 500;font-size: 11px;color: #fff;padding: 4px 8px}

.cafeCategory_section{padding: 30px 16px 10px;display: flex;flex-wrap: wrap}
.cafeCategory_section .categoryBox{width: calc(25% - 6px);min-width: calc(25% - 6px);margin: 20px 8px 0 0}  
.cafeCategory_section .categoryBox:nth-child(-n+4){margin-top: 0}
.cafeCategory_section .categoryBox:nth-child(4n){margin-right: 0}

.recent_area .horizontalScroll{padding: 0 20px}
.recent_section{margin: 20px 0 0;padding: 0 0 20px;background: #F6F6F6}
.cafe_section_title{font-weight: 700;padding: 20px 20px 14px}
.recent_item{background: #FFFFFF;border-radius: 15px;padding: 14px 10px;min-width: 130px;margin: 0 10px 0 0;width:130px}
.recentItemImg{background-repeat: no-repeat;background-size: cover;background-position: center;border-radius: 100%;margin: 0 auto;width:64px;height: 64px}
.recentItem_info{width: 100%}
.recentItem_name{width:100%;margin: 6px 0 0;font-weight: 700;font-size: 12px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.recentItem_text{width:100%;color: #838383;font-size: 10px;margin: 4px 0 0;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical}

.thema_section{margin: 0 0 30px}
.thema_section .horizontalScroll{padding: 0 20px}

.basicListItem{margin: 0 14px 0 0;width:130px;min-width: 130px}
.basicListItemImg{background-repeat: no-repeat;background-position: center;background-size: cover;background-color: #f1f1f1;padding: 0 0 100%;width: 100%;border-radius: 20px;display: block}
.basicListItem_info{padding: 10px 0 0;width: 100%;text-align: left}
.basicListItem_name{font-weight: 500;font-size: 13px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.basicListItem_text{font-size: 11px;color: #838383;margin: 4px 0 0;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical}

.popular_area .horizontalScroll{padding: 0 20px}
.popular_item{margin: 0 14px 0 0;min-width: 240px;width: 240px;text-align: left;overflow-x: hidden}
.popularItemImg{background-repeat: no-repeat;background-size: cover;background-position: center;background-color: #f1f1f1;width: 100%;padding: 0 0 66.66%;border-radius: 20px;min-width: 100%}
.popularItem_info{margin: 10px 0 0;width:100%}
.popularItem_name{width:100%;font-weight: 700;font-size: 14px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.popularItem_text{width:100%;color: #838383;font-size: 12px;margin: 4px 0 0;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical}
.popularItem_tagBox{margin: 10px 0 0;display: flex;width:100%}

/*list*/
.listPage{padding: 107px 0 50px}
.list_section{padding: 24px 20px 0}
.cafeListItem + .cafeListItem{margin: 24px 0 0}
.cafeListItemImgBox{display: flex;width:100%}
.cafeListItemImg{background-repeat: no-repeat;background-size: cover;background-position: center;background-color: #f1f1f1;width: 100%;padding: 0 0 65.27%;border-radius: 20px}
.cafeListItem_subImgBox{width: 23.43%;min-width: 23.43%;margin: 0 0 0 6px}
.cafeListItem_subImgBox .cafeListItemImg{width:100%;padding-bottom: 100%}
.cafeListItem_subImgBox .cafeListItemImg + .cafeListItemImg{margin: 6px 0 0}
.cafeListItemImgBox .swiper{width:calc(76.57% - 6px)}

/*category*/
.category_section{padding: 10px 20px;border-bottom: 6px solid #f6f6f6}
.categoryBox{width: 70px;min-width: 70px;display: flex;align-items: center;justify-content: center}
.btn_category{color: #838383;font-weight: 500;font-size: 11px;width:100%;height:100%}  
.btn_category span{width:100%;text-align: center}
.categoryBox.active .btn_category{color: #5E5E5E;font-weight: 700}
.btn_category img{width: 38px;height: 38px;margin: 0 auto 6px;border-radius: 12px}
.categoryBox.textCategory{width: auto;min-width: 0;display: block;min-width: 60px;flex-shrink: 0}
.categoryBox.textCategory + .categoryBox{margin: 0 0 0 6px}
.categoryBox.textCategory .btn_category{font-size: 13px;padding: 4px 10px;border: 1px solid #E7E7E7;border-radius: 44px;min-width: 60px;width: auto;height: auto;display: block}
.categoryBox.textCategory.active .btn_category{color: #fff;background: #5E5E5E;border-color: #5E5E5E;font-weight: 500}
.horizontalScroll .categoryBox + .categoryBox{margin: 0 0 0 6px}
.horizontalScrollBox{padding: 12px 20px 0}

/*giftList*/
.mainList_section{padding: 24px 20px 50px}
.listItem_imgBox{display: block;width: 100%;position: relative}
.listItem_imgBox .swiper-pagination{position: absolute;right: 12px;bottom: 12px;z-index: 1;background: rgba(0, 0, 0, 0.4);border-radius: 33px;font-weight: 500;font-size: 11px;color: #fff;padding: 4px 8px}
.listItemImg{background-repeat: no-repeat;background-size: cover;background-color: #f1f1f1;background-position: center;width: 100%;padding: 0 0 62.5%;border-radius: 25px}
.listItem_infoBox{padding: 10px 0 0;display: flex;justify-content: space-between;align-items: flex-start}
.listItem_info{width: calc(100% - 46px);text-align: left}
.listItem_title{font-weight: 500;font-size: 14px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden}
.listItem_text{font-size: 12px;color: #838383;white-space: nowrap;text-overflow: ellipsis;overflow: hidden}
.store_listItem + .store_listItem{margin: 24px 0 0}

/*detail*/
.detail_bannerItem{background-repeat: no-repeat;background-position: center;background-size: cover;background-color: #f1f1f1;width: 100%;padding: 0 0 66.66%;border-radius: 25px}
.detail_bannerSlide .swiper-pagination{position: absolute;right: 32px;bottom: 12px;z-index: 1;background: rgba(0, 0, 0, 0.4);border-radius: 33px;font-weight: 500;font-size: 11px;color: #fff;padding: 4px 8px}
.detail_section{padding: 54px 0 0}
.detail_bannerSlide .swiper{padding: 0 20px;overflow: visible}
.detail_titleBox{padding: 14px 20px 0}
.detail_title{font-weight: 700;font-size: 16px}
.detail_text{color: #838383;font-size: 12px;margin: 3px 0 0}
.detail_tagBox{margin: 20px 20px 0}
.tagItem{padding: 4px 8px;color: #5E5E5E;font-weight: 500;font-size: 13px;background: #EFEFEF;border-radius: 4px;white-space: nowrap;text-align: center}
.tagItem + .tagItem{margin: 0 0 0 6px}
.detailInfoMenu{margin: 14px 0 0;display: flex;align-items: flex-start;justify-content: space-between;padding: 0 20px 16px}
.detailInfoMenuItem{width: calc(25% - 7.5px);display: block;padding: 6px 5px;text-align: center;font-weight: 500;font-size: 11px;color: #838383}
.detailInfoMenuItem img{margin: 0 auto 5px;width:24px}

.storeDetail_infoSection{padding: 0 0 40px}
.storeDetail_infoNameArea{padding: 20px 20px 0}
.storeDetail_infoName{font-weight: 500;font-size: 14px}
.storeDetail_infoAddr{font-size: 12px;color: #838383}
.btn_addrCopy{padding: 4px 6px;background: #F6F6F6;border-radius: 7px;font-size: 12px;color: #5E5E5E;display: flex;align-items: center;margin: 8px 0 0}
.btn_addrCopy img{margin: 0 2px 0 0}
.copyInput{position: fixed;top: 0;left: -1000px;transform: translate(-100%,-100%)}
.storeDetail_infoEditArea{margin: 20px 0 0;padding: 0 20px}
.storeDetail_infoEditArea *{font-weight: revert;color: revert;font-size: revert;line-height: normal;letter-spacing: normal}
.storeDetail_infoEditArea img{max-width: 100%}
.storeDetail_infoTextArea{padding: 12px 20px}
.storeDetail_infoTextItem{display: flex;align-items: flex-start;margin: 8px 0 0}
.storeDetail_infoTextName{color: #5E5E5E;font-weight: 500;font-size: 13px;min-width: 120px;width: 120px}
.storeDetail_infoText{font-size: 13px;color: #5E5E5E;width: calc(100% - 120px)}
.storeDetail_infoMap{margin: 20px auto 0;min-height: 200px;height:55vw;border-radius: 25px;overflow: hidden;width:calc(100% - 40px)}

.detailEventBox{width: calc(100% - 40px);display: flex;align-items: center;margin: 14px auto 0;background: #F5B79B;border-radius: 10px;padding: 8px 11px}
.detailEventBox>img{margin: 0 8px 0 0}
.detailEventText{color: #fff;font-weight: 700;font-size: 13px}

.detail_bannerItemBox{padding: 0 20px}
.menuDetail_topInfo{padding: 30px 20px 20px;display: flex;align-items: center;justify-content: space-between}
.detailPriceSection{padding-right: 20px}
.detailBasicPrice{color: #C7C7C7;text-decoration-line: line-through;font-weight: 500;font-size: 14px;margin: -20px 0 0}
.detailPriceArea{display: flex;align-items: flex-start}
.detailSale_val{color: #DE7979;font-weight: 500;font-size: 18px;margin: 0 8px 0 0}
.detailPrict{font-weight: 500;font-size: 18px}
.countBox{border: 1px solid #C7C7C7;border-radius: 10px;display: flex;justify-content: space-between;width: 110px;min-width: 110px}
.btn_p,.btn_m{width: 33.33%;min-width: 33.33%;height: 40px}
.btn_p img,.btn_m img{margin: 0 auto}
.countInput{width: 33.33%;min-width: 33.33%;font-weight: 500;font-size: 14px;border: 0;height: 40px;text-align: center}

.productInfo_section{border-top: 6px solid #F6F6F6}

.etc_area{padding: 20px}

.essential_option_area .option_titleBox{padding: 9px 20px}
.option_area + .option_area{border-top: 1px solid #F6F6F6}
.option_titleBox{display: flex;justify-content: space-between;align-items: center;padding: 14px 20px;border-bottom: 1px solid #F6F6F6}
.option_title{font-weight: 700;font-size: 14px;color: #5E5E5E}
.option_caption{color: #2BBFA6;font-weight: 500;font-size: 12px;background: rgba(143, 231, 216, 0.2);border-radius: 7px;padding: 4px 8px}
.option_selList_input + label{padding: 15px 20px 15px 51px;color: #5E5E5E;font-size: 14px;display: flex;align-items: center;justify-content: space-between}
.option_selList_input[type=radio] + label{background: url(/assets/images/basic/radio_off.svg) no-repeat left 20px center;background-size: 24px}
.option_selList_input[type=radio]:checked + label{background-image: url(/assets/images/basic/radio_on.svg)}
.option_selList_input[type=checkbox] + label{background: url(/assets/images/basic/check_off.svg) no-repeat left 20px center;background-size: 24px}
.option_selList_input[type=checkbox]:checked + label{background-image: url(/assets/images/basic/check_on.svg)}
.option_priceBox{display: flex;align-items: center;justify-content: flex-end}
.option_sale{color: #DE7979;font-size: 13px;margin: 0 4px 0 0}
.option_price{color: #5E5E5E;font-weight: 500;font-size: 13px}    

/*tap*/          
.tapSection{border-top: 6px solid #f6f6f6;border-bottom: 1px solid #d8d8d8}
.tapBox{display: flex;justify-content: space-between}
.tapItem{min-height: 40px;padding: 0 5px;color: #D8D8D8;font-weight: 500;font-size: 13px;border-bottom: 2px solid transparent;width:100%}
.tapItem.active{border-color: #1e1e1e;color: #1E1E1E}
.tapItem:first-child:nth-last-child(1){width: 50%}
.tapItem:first-child:nth-last-child(2),
.tapItem:first-child:nth-last-child(2) ~ .tapItem{width: 50%}
.tapItem:first-child:nth-last-child(3),
.tapItem:first-child:nth-last-child(3) ~ .tapItem{width: 33.3333%}
.tapItem:first-child:nth-last-child(4),
.tapItem:first-child:nth-last-child(4) ~ .tapItem{width: 25%}
.tapItem:first-child:nth-last-child(5),
.tapItem:first-child:nth-last-child(5) ~ .tapItem{width: 20%}
.tapItem:first-child:nth-last-child(6),
.tapItem:first-child:nth-last-child(6) ~ .tapItem{width:16.6%}

/*menuList*/
.menuCategory{min-height: 52.19px}
.menuList_categoryTitle{font-weight: 700;font-size: 14px;padding: 14px 20px;border-bottom: 1px solid #F6F6F6}
.menuCategory .horizontalScroll{padding: 12px 20px;top: 106px;z-index: 9001}
.menuListItem{padding: 14px 20px;display: flex;align-items: flex-start;text-align: left;width:100%}
.menuListItemImg{background-repeat: no-repeat;background-size: cover;background-position: center;background-color: #f1f1f1;border-radius: 15px;width: 64px;height: 64px;min-width: 64px}
.menuListItem_info{margin: 0 0 0 12px;width:calc(100% - 76px)}
.menuListItem_name{width:100%;font-weight: 500;font-size: 13px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.menuListItem_text{width:100%;font-size: 11px;color: #838383;margin: 4px 0 0;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical}
.menuListItem_priceBox{margin: 4px 0 0;display: flex}
.menuListItem_price{font-weight: 500;font-size: 13px}
.menuListItem_price_saleVal{font-weight: 700;font-size: 13px;color: #DE7979}
.menuListItem_price_basic{margin: 0 4px 0 8px;font-weight: 500;font-size: 13px;color: #C7C7C7;text-decoration-line: line-through}

/*pick*/
.pickList_section{padding: 54px 20px 116px}
.pickItemImg{background-repeat: no-repeat;background-position: center;background-size: cover;padding: 0 0 66.66%;border-radius: 25px;background-color: #f1f1f1}
.pickTextBox{margin: 10px 0 0}
.pick_title{font-weight: 700;font-size: 14px}
.pick_text{margin: 4px 0 0;color: #838383;font-size: 12px;white-space: pre-wrap}
.pickTextBox.close .pick_title{text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.pickTextBox.close .pick_text{text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical}
.btnPickMore{margin: 7px 0 0;display: flex;align-items: center;font-size: 12px;color: #838383}      
.pickItem + .pickItem{margin: 40px 0 0}
.pickItem .swiper{margin: 13px 0 0}
.pickStoreItem{border: 1px solid #E7E7E7;border-radius: 15px;padding: 10px;display: flex;align-items: flex-start;justify-content: space-between}
.pickStoreItem .menuListItem{width: calc(100% - 49px);padding: 0}
.pickStoreItem .menuListItem_info{text-align: left}
.pickItemImgBox .swiper-pagination{position: absolute;right: 12px;bottom: 12px;z-index: 1;background: rgba(0, 0, 0, 0.4);border-radius: 33px;font-weight: 500;font-size: 11px;color: #fff;padding: 4px 8px}

/*sh*/
.sh_section{padding: 94px 0 50px}
.header_shBox{position: absolute;right: 20px;top: 50%;transform: translateY(-50%);width: calc(100% - 80px)}
.header_sh{min-height: 44px;width: 100%;border: 1px solid #E7E7E7;border-radius: 7px;padding: 10px;font-size: 14px}
.btn_header_sh{position: absolute;right: 10px;top: 50%;transform: translateY(-50%);z-index: 1}
.sh_area{padding: 20px 20px 0;display: flex;flex-wrap: wrap;justify-content: space-between}
.shTotal{font-size: 14px;color: #5E5E5E;width: 100%;margin: 0 0 10px}

/*calendarListItem*/
.calendarList_area{padding: 20px 20px 0}
.calendarList{display: flex;flex-wrap: wrap;justify-content: space-between}
.calendarListItem{margin: 20px 0 0;width:calc(50% - 7px)}
.calendarListItem:nth-child(-n+2){margin-top: 0}
.calendarListItemImgBox{position: relative}
.calendarListItemImg{background-repeat: no-repeat;background-position: center;background-size: cover;background-color: #f1f1f1;padding: 0 0 100%;width: 100%;border-radius: 20px;display: block}
.btn_likeMin{position: absolute;right: 10px;bottom: 10px}
.calendarListItem_info{padding: 10px 0 0;width: 100%;text-align: left}
.calendarListItem_name{font-weight: 500;font-size: 13px;width:100%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.calendarListItem_text{font-size: 11px;color: #838383;margin: 4px 0 0;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical}
.notList{display: flex;align-items: center;justify-content: center;flex-direction: column;font-weight: 500;font-size: 14px;color: #C7C7C7;width: 100%;min-height: 50vh;text-align: center}
.notList>img{margin: 0 0 14px}
.notList.minVar{min-height: 180px}

/*gift*/
.giftType_selBox{background: #F6F6F6;border-radius: 34px;padding: 4px}
.giftType_sel{border-radius: 44px;padding: 4px 10px;font-weight: 500;font-size: 14px;color: #ACACAC}
.giftType_sel.active{color: #fff;background: #3A3A3A}

.giftList{padding: 104px 20px 40px}
.giftListItem{padding: 15px 0}
.giftListItem + .giftListItem{border-top: 1px solid #F6F6F6}
.giftStatus{margin: 0 0 20px;display: flex;align-items: center;justify-content: space-between;width:100%}
.giftStatusText{font-weight: 500;font-size: 13px;color: #5E5E5E}
.giftStatusText span{font-weight: 700;margin: 0 4px 0 0}
.giftStatusText span.rColor{color: #DE7979}
.giftListItem_infoBox{display: inline-flex;align-items: flex-start}
.giftListItem_infoImgBox{background-repeat: no-repeat;background-position: center;background-size: cover;border-radius: 15px;overflow: hidden;background-color: #f1f1f1;position: relative;width: 64px;min-width: 64px;height: 64px}
.giftListItem_infoTextBox{margin: 0 0 0 12px;max-width: calc(100vw - 40px - 64px - 12px);transform: translateY(-4px)}
.giftListItem_store{line-height: 1;display: inline-block;font-weight: 700;font-size: 11px;color: #ACACAC;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;cursor: pointer}
.giftListItem_nameBox{margin: 10px 0 0}
.giftListItem_name{width:100%;font-weight: 700;font-size: 13px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.giftListItem_price{margin: 10px 0 0;color: #5E5E5E;font-weight: 500;font-size: 13px}
.giftListItem_btnBox{border: 1px solid #E7E7E7;border-radius: 12px;display: flex;justify-content: space-between;margin: 14px 0 0}
.giftListItem_btn{position: relative;width: 100%;font-weight: 500;font-size: 13px;color: #5E5E5E;padding: 14px 10px}
.giftListItem_btn + .giftListItem_btn::before{content: "";background: #D9D9D9;width: 1px;height: 4px;position: absolute;left: 0;top: 50%;transform: translate(-50%,-50%)}      
.giftListItem_btn:first-child:nth-last-child(2),
.giftListItem_btn:first-child:nth-last-child(2) ~ .giftListItem_btn{width: 50%}      
.giftListItem_option{width:100%;color: #838383;font-size: 11px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.giftListItem_infoCount{background: rgba(58, 58, 58, 0.6);padding: 3px;font-weight: 700;font-size: 12px;color: #fff;text-align: center;position: absolute;bottom: 0;left: 0;width: 100%;backdrop-filter: blur(2px);-webkit-backdrop-filter: blur(2px);border-radius: 0 0 15px 15px}
.giftDetailPage{padding-top: 54px}

.giftCard{padding: 0 20px 30px}
.giftCard_img{border-radius: 25px;background: #f1f1f1;padding: 0 0 100%;background-repeat: no-repeat;background-position: center;background-size: cover}
.giftCard_name{font-weight: 700;font-size: 14px;margin: 14px 0}
.giftCard_text{font-weight: 400;font-size: 14px;line-height: 150%;padding: 10px;background: #F6F6F6;border-radius: 7px;white-space: pre-wrap;color: #5E5E5E;min-height: 100px}

.giftCard_order_info{padding-bottom: 136px;}
.giftCard_order_info_title{padding: 10px 20px; font-size:14px; font-weight: 700;}

.rightPopup .pageBtn.textType{background: rgba(131, 131, 131, 0.6);border: 0;color: #fff}
.pageBtn.textType{justify-content: space-between;padding: 0 18px;background: #F6F6F6;border-color: #F6F6F6;color: #5E5E5E;font-size: 13px}
.pageBtn.textType.basicText{text-align: center;justify-content: center;color: #707070;font-weight: 400;font-size: 12px;height: auto;background: transparent;border: 0}
.pageBtn + .pageBtn{margin: 10px 0 0}
.pageBtn .qrIcon{margin: 0 7px 0 0}
.giftDetailItem{padding: 14px 20px 30px}
.giftDetailItem_imgBox{background-repeat: no-repeat;background-size: cover;background-position: center;width: 210px;height: 210px;border-radius: 25px;margin: 0 auto;position: relative;overflow: hidden}
.giftDetailItem_dDay{font-weight: 700;font-size: 14px;padding: 14px 10px;text-align: center;position: absolute;bottom: 0;left: 0;width: 100%;color: #fff;background: rgba(58, 58, 58, 0.8);backdrop-filter: blur(2.5px);-webkit-backdrop-filter: blur(2.5px);border-radius: 0 0 25px 25px}
.giftDetailItem_dDay.endDDay{color: #ACACAC}
.giftDetailItem_store{background: #fff;border: 1px solid #E7E7E7;border-radius: 10px;display: flex;align-items: center;padding: 8px;max-width: 100%;margin: 16px auto 0}
.giftDetailItem_storeImg{background-repeat: no-repeat;background-position: center;background-size: cover;min-width: 20px;background-color: #f1f1f1;width: 20px;height: 20px;border-radius: 7px;margin: 0 6px 0 0}
.giftDetailItem_storeName{font-weight: 700;font-size: 13px;text-align: left;width: calc(100% - 7px);text-overflow: ellipsis;overflow: hidden;white-space: nowrap;color: #838383}
.giftDetailItem_name{font-weight: 700;font-size: 16px;line-height: 150%;text-align: center;margin: 10px 0 0}
.giftDetailItem_option{color: #838383;font-weight: 400;font-size: 12px;line-height: 17px;margin: 7px 0 0;text-align: center}

.giftDetailItem.wItem .giftDetailItem_store{background: rgba(30, 30, 30, 0.7);border: 1px solid #5E5E5E}    
.giftDetailItem.wItem .giftDetailItem_storeName{color: #E7E7E7}
.giftDetailItem.wItem .giftDetailItem_name{color: #FFFFFF}
.giftDetailItem.wItem .giftDetailItem_option{color: #ACACAC}

.rightPopup.bPopup .pageBtn_box{background: transparent}

/*qr*/
.qrCover{position: fixed;top: 0;left: 0;z-index: 10;height: calc(100% - 104px);width: 100%;display: flex;flex-wrap: wrap}
.qrCover .t{width: 100%;background: rgba(30, 30, 30, 0.4);position: relative;height: calc(50% - 140px)}
.qrCover .b{width: 100%;background: rgba(30, 30, 30, 0.4);height: calc(50% - 140px)}
.qrCover .l{width: calc(50% - 140px);height: 280px;background: rgba(30, 30, 30, 0.4)}
.qrCover .r{width: calc(50% - 140px);height: 280px;background: rgba(30, 30, 30, 0.4)}
.qrCover .c{width: 280px;height: 280px;position: relative}
.qrCover .lt{position: absolute;top: 0;left: 0;transform: translate(-2px,-2px)}
.qrCover .rt{position: absolute;right: 0;top: 0;transform: translate(2px,-2px)}
.qrCover .lb{position: absolute;left: 0;bottom: 0;transform: translate(-2px,2px)}
.qrCover .rb{position: absolute;bottom: 0;right: 0;transform: translate(2px,2px)}

.giftQr{position: fixed;top: 0;left: 0;width: 100%;height: 100%}
.qrBox{width: 100%;height: 100%}
#qrItem{position: relative;width: 100%;height: 100%}
#qrItem video{display: block;width: 100% !important;height: 100% !important;object-fit: fill !important}
#qrItem canvas{display: none;width: 100% !important;height: 100% !important}
#qrItem>div{display: none !important}
.qrBtnBox{background: #FFFFFF;border-radius: 24px 24px 0px 0px;padding: 20px;position: fixed;bottom: 0;left: 0;width: 100%}
.qrBtnText{font-weight: 500;font-size: 14px;color: rgba(0, 0, 0, 0.8);text-align: center;padding: 0 0 20px}

/*order*/
.orderPage{padding: 54px 0 104px}
.order_chkArea{padding: 20px}
.orderSection + .orderSection{border-top: 6px solid #F6F6F6}

.orderSection_title{padding: 14px 20px;font-weight: 700;font-size: 14px;color: #5E5E5E}
.order_type_itemBox{padding: 0 20px 20px;display: flex;flex-wrap: wrap;justify-content: space-between}
.order_type_item{width: calc(50% - 4px);margin: 8px 0 0}
.order_type_item:nth-child(-n+2){margin-top: 0}
.order_type_item label{border: 1px solid #E7E7E7;border-radius: 15px;display: flex;align-items: center;justify-content: center;font-weight: 500;font-size: 15px;height: 54px;width: 100%}
.order_type_item label img{margin: 0 6px 0 0}
.order_type_item input:checked + label{background: #3A3A3A;border-color: #3a3a3a;font-weight: 700;color: #fff}
.order_type_item.minVar label{border-radius: 7px;height:37px;font-size: 12px}
.orderInfoText_area{border-top: 1px solid #F6F6F6}
.order_infoItem{padding: 10px 20px;display: flex;align-items: center;justify-content: space-between}
.order_infoName{font-size: 14px;color: #5E5E5E}
.order_infoText{font-weight: 500;font-size: 13px;color: #5E5E5E;text-align: right}        
.order_infoText>span{text-decoration-line: line-through;color: #C7C7C7;font-weight: 500;font-size: 13px;margin: 0 6px 0 0}
.orderProductItem_store{display: flex;align-items: center;text-align: left;width: 100%;padding: 10px 20px;border-bottom: 1px solid #F6F6F6;font-weight: 700;font-size: 14px;color: #5E5E5E}
.orderProductItem_store span{text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width:calc(100% - 24px);line-height: 160%}
.orderProductItem_box{padding: 20px;display: flex;align-items: flex-start;justify-content: space-between}
.orderProductItem_img{border-radius: 15px;background-repeat: no-repeat;background-size: cover;background-position: center;background-color: #f1f1f1;width: 64px;height: 64px;min-width: 64px}
.orderProductItem_info{width: calc(100% - 64px - 12px);margin: 0 0 0 12px}
.orderProductItem_name{font-weight: 500;font-size: 13px;color: #333333}
.orderProductItem_option{color: #838383;font-size: 11px;margin: 8px 0 19px;line-height: 150%}
.orderProductItem_priceBox{margin: 8px 0 0;display: flex;align-items: flex-end;justify-content: space-between}
.orderProductItem_priceItem{padding: 0 10px 0 0}
.orderProductItem_basicPrice{color: #C7C7C7;text-decoration-line: line-through;font-weight: 500;font-size: 11px;margin: 0 0 2px}
.orderProductItem_priceArea{display: flex}
.orderProductItem_sale_val{color: #DE7979;font-weight: 500;margin: 0 4px 0 0}
.orderProductItem_prict{font-weight: 500}
.orderForm_area{padding: 0 20px 20px}
.orderImgSelArea{margin: 20px 0;width: 100vw;transform: translateX(-20px)}
.orderImgSelArea .horizontalScroll{padding: 0 20px}
.btn_imageSel{width: 70px;min-width: 70px;height: 70px;border: 1px solid #E7E7E7;border-radius: 15px;background-color: #F6F6F6;background-repeat: no-repeat;background-size: cover;background-position: center;margin: 3px 9px 3px 0;display: flex;align-items: center;justify-content: center}
.btn_imageItemSel{border:3px solid #fff;overflow: hidden;width: 76px;min-width: 76px;height: 76px;transform: translate(0,-3px);}
.btn_imageItemSelFirst{margin: 3px 9px 3px 2px;}
.btn_imageItemSel.active{border-color: #F5B79B}
.btn_imageSel img{width: 28px}  
.btn_imageItemSel .btn_imageItemSel_img{width:100%}

.order_phoneTypeArea{border-bottom: 6px solid #F6F6F6;padding: 0 20px 20px}
.order_phoneType_itemBox{display: flex;justify-content: space-between}
.order_phone_inputBox{margin: 10px 0 0;position: relative}
.phoneShInput .inputItem{background: url(/assets/images/basic/sh.svg) no-repeat right 10px center;cursor: pointer;padding-right: 45px;background-color: rgba(231, 231, 231, 0.3)}
.btn_phoneShReSet{position: absolute;right: 10px;top: 50%;transform: translateY(-50%);display: none}

.order_phone_inputBox.active .phoneShInput .inputItem{background:rgba(231, 231, 231, 0.3)}
.order_phone_inputBox.active .btn_phoneShReSet{display: block}

.phoneShItem{padding: 0 20px}
.phoneShItem label{display: block;padding: 16px 0;background: url(/assets/images/basic/radio_off.svg) no-repeat right center;background-size: 24px;border-bottom: 1px solid #F6F6F6;}
.phoneShItem input:checked + label{background-image: url(/assets/images/basic/radio_on.svg)}
.phoneShItemName{font-size: 13px;font-weight: 500;color: #5E5E5E;margin: 0 0 2px;}
.phoneShItemNumber{font-size: 15px;font-weight: 700;}
.phoneShItem_section{padding: 0 0 98px}

/*accordion*/
.accordionBox{background: #F6F6F6;border-radius: 15px;border: 1px solid #f6f6f6;padding: 0;width: 100%;transition: background 0.3s}
.accordionBox.active{background: #fff}
.accordionBox + .accordionBox{margin: 8px 0 0}
.accordionTitle{display: flex;align-items: center;justify-content: space-between;padding: 16px 14px 14px;width:100%;background-repeat: no-repeat;background-position: right 14px center}
.accordionTitle>p{font-weight: 700;font-size: 14px;width: 100%;text-align: left;padding-right: 24px}
.accordionText{display: none;margin: 8px 0 0;padding: 0 20px 14px}
.accordionText>p{color: #838383;font-size: 12px;white-space:pre-wrap}

/*mypage*/
.myPage{padding: 54px 0 66px}
.mypageSection{padding: 0 20px}
.mypageMain_info_area{display: flex;align-items: flex-end;justify-content: space-between}
.mypageMain_infoBox{padding: 0 15px 0 0}
.mypageMain_name{font-weight: 500;font-size: 24px;color: #333}
.mypageMain_phone{font-weight: 500;font-size: 13px;color: #707070;margin: 6px 0 0}
.editLink{display: flex;align-items: center;justify-content: flex-end;color: #ACACAC;font-size: 13px}
.mypageMain_menuArea{margin: 20px 0 0}
.mypage_linkItem{display: flex;width: 100%;text-align: left;align-items: center;justify-content: space-between;padding: 15px 14px;background: #F6F6F6;border-radius: 15px}
.mypage_linkItem + .mypage_linkItem{margin: 10px 0 0}
.mypage_linkName{font-weight: 500}
.mypage_linkItemBox{display: flex;align-items: center;justify-content: flex-end}
.mypage_linkText{font-weight: 500}
.mypage_linkText.rColor{color: #DE7979}
.mypageMain_menuBox{margin: 10px 0 0;background: #FFFFFF;border: 1px solid #E7E7E7;border-radius: 15px;display: flex;justify-content: space-between}
.mypageMain_menuItem{width: 33.33%;display: flex;align-items: center;justify-content: flex-start;flex-direction: column;padding: 15px 5px;font-weight: 500;font-size: 11px;color: #5E5E5E}
.mypageMain_menuItem img{margin: 0 0 6px}
.mypageSubSection{padding: 20px 20px 40px}

.mypageWithdrawal_title{font-weight: 500;font-size: 22px}
.mypageWithdrawal_text{font-size: 14px;color: #5E5E5E;margin: 10px 0 0}
.mypageWithdrawal_textBox{margin: 0 0 30px}
.mypage_links{padding: 0 0 30px}

.likeList_section{padding: 94px 0 50px}
.mypage_link_subItemBox{margin: 10px 0 30px}
.mypage_link_subItemBox .mypage_linkItem{border: 1px solid #E7E7E7;background: #FFFFFF}

.myOrderList_section{padding: 54px 20px 50px}
.orderListItem + .orderListItem{border-top: 1px solid #F6F6F6}
.orderListItem{text-align: left;padding: 15px 0;width:100%}
.orderList_head{display: flex;align-items: center;justify-content: space-between;margin: 0 0 6px}
.orderList_date{color: #5E5E5E;font-weight: 500;font-size: 13px}
.orderList_data{padding: 18px;background: #F6F6F6;border: 1px solid #E7E7E7;border-radius: 15px;display: flex;justify-content: space-between}
.orderList_img{background-repeat: no-repeat;background-position: center;background-size: cover;border-radius: 20px;width: 92px;min-width: 92px;height: 92px;background-color: #e2e2e2}
.orderList_info{margin: 0 0 0 12px;width: calc(100% - 92px - 12px)}
.orderList_store{font-weight: 700;font-size: 11px;color: #ACACAC;margin: 0 0 3px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.orderList_name{font-weight: 700;font-size: 13px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.orderList_infoListBox{margin: 12px 0 0}
.orderList_infoList{display: flex;width: 100%}
.orderInfo_options{font-size: 10px;color: #838383}
.orderList_infoList_name{color: #5E5E5E;font-weight: 500;font-size: 10px;min-width: 60px}
.orderList_infoList + .orderList_infoList{margin: 5px 0 0}    
.orderList_infoList_val{width: calc(100% - 70px);margin: 0 0 0 10px;color: #5E5E5E;font-size: 10px}                

.myOrderDetail_section{padding: 64px 0 60px}
.myOrderDetail_section.addBtns{padding-bottom: 138px}
.orderDetailInfo{padding: 0 20px}
.orderDetailInfoBox{padding: 16px;border: 1px solid #E7E7E7;border-radius: 15px}
.myOrderDetail_status{font-weight: 500;font-size: 12px;padding: 4px 8px;background: #E7E7E7;border-radius: 7px;color: #5E5E5E;text-align: center}
.myOrderDetail_status.gColor{color: #2BBFA6;background: rgba(143, 231, 216, 0.2)}
.myOrderDetail_status.rColor{color: #DE7979;background: rgba(222, 121, 121, 0.2)}
.myOrderDetail_section .orderList_data{padding: 0;background: transparent;border: 0;border-radius: 0}  
.orderDetailInfo_listBox{margin: 20px 0 0}
.orderList_infoDetail .orderList_infoList_name{font-size: 13px;min-width: 87px}
.orderList_infoDetail .orderList_infoList_val{width: calc(100% - 97px);font-size: 13px}
.orderList_infoDetail .orderList_infoList_val.typeBold{font-weight: 700}
.orderList_infoDetail .orderList_infoList_val.typeBold.gColor{color: #2BBFA6}
.orderList_infoDetail .orderList_infoList_val.typeBold.rColor{color: #DE7979}
.detail_totalPrice{display: flex;align-items: center;justify-content: space-between;bottom: 0;left: 0;width: 100%;position: fixed;padding: 10px 20px;background: #fff}
.detail_totalPrice.addBtn_div{bottom: 78px}
.detail_totalPrice_name{font-size: 14px;color: #5E5E5E}
.detail_totalPrice_text{font-weight: 700;font-size: 13px;color: #5E5E5E}    

/*cs*/
.btnSubText{font-size: 12px;color: #707070;text-align: center;margin: 0 0 10px}
.faq_section{padding: 74px 20px 135px}  

.cs_section{padding: 54px 20px 108px}
.csListItem{text-align: left;width: 100%;display: block;padding: 20px 0 10px}
.csListItem + .csListItem{border-top: 1px solid #F6F6F6}
.csList_type{color: #ACACAC;margin: 0 0 3px;font-weight: 700;font-size: 11px}
.csList_titleBox{display: flex;align-items: center;justify-content: space-between;width: 100%}
.csList_title{font-weight: 500;font-size: 14px}
.csList_status{display: inline-block;font-weight: 500;font-size: 12px;color: #5E5E5E;background: #E7E7E7;border-radius: 7px;padding: 4px 8px;margin: 11px 0 0}  
.csList_status.gColor{color: #2BBFA6;background: rgba(143, 231, 216, 0.2)}

.csDetailProduct_infoBox{display: flex;align-items: flex-start;padding: 14px;background: #F6F6F6;border: 1px solid #E7E7E7;border-radius: 15px;margin: 14px 0 0}
.csDetailProduct_infoImgBox{background-repeat: no-repeat;background-position: center;background-size: cover;border-radius: 15px;overflow: hidden;background-color: #f1f1f1;position: relative;width: 64px;min-width: 64px;height: 64px}
.csDetailProduct_infoTextBox{margin: 0 0 0 12px;width: calc(100% - 64px - 12px)}
.csDetailProduct_store{font-weight: 700;font-size: 11px;color: #ACACAC;padding: 0 0 4px;width:100%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.csDetailProduct_name{width:100%;font-weight: 500;font-size: 13px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.csDetailProduct_price{margin: 8px 0 0;font-weight: 500;font-size: 13px}
.csDetail_section{padding: 64px 20px 30px}
.csDetail_type{font-weight: 700;font-size: 13px;color: #ACACAC;margin: 0 0 4px}
.csDetail_title{font-weight: 500}
.csDetail_text{margin: 14px 0 0;font-size: 13px;color: #5E5E5E;line-height: 180%;white-space: pre-wrap}
.csDetail_imgArea{margin: 20px 0 0;display: flex;align-items: flex-start;overflow-x: auto;width: 100vw;transform: translateX(-20px);padding: 0 20px}
.csDetail_img{background-repeat: no-repeat;background-size: cover;background-position: center;border-radius: 15px;width: 70px;min-width: 70px;height: 70px;background-color: #f1f1f1}
.csDetail_img + .csDetail_img{margin: 0 0 0 12px}
.csDetail_imgArea + .bankItem{margin: 20px 0 0}
.bankItem{margin: 8px 0 0;display: flex;align-items: center;width: 100%}
.bankName{min-width: 120px;font-weight: 500;font-size: 13px;color: #5E5E5E}
.bankVal{color: #5E5E5E;font-size: 13px;margin: 0 0 0 10px;width: calc(100% - 120px - 10px)}
.csDetail_answer{padding: 20px 20px 30px;background: rgba(246, 246, 246, 0.7)}
.csDetail_answer_title{color: #F87A1C;font-weight: 700;font-size: 13px;margin: 0 0 10px}
.csDetail_answer_text{font-size: 13px;line-height: 180%;color: #5E5E5E;white-space: pre-wrap}
.csDetailProduct_infoCount{background: rgba(58, 58, 58, 0.6);padding: 3px;font-weight: 700;font-size: 12px;color: #fff;text-align: center;position: absolute;bottom: 0;left: 0;width: 100%;backdrop-filter: blur(2px);-webkit-backdrop-filter: blur(2px)}

.csWriting_section{padding: 74px 20px 108px}
.csWriting_section.addBtns{padding-bottom: 148px}

.productSelItem_infoBox{display: flex;align-items: flex-start}
.productSelItem_infoImgBox{background-repeat: no-repeat;background-position: center;background-size: cover;border-radius: 15px;overflow: hidden;background-color: #f1f1f1;position: relative;width: 64px;min-width: 64px;height: 64px}
.productSelItem_infoTextBox{margin: 0 0 0 12px;width: calc(100% - 64px - 12px)}
.productSelItem_store{font-weight: 700;font-size: 11px;color: #ACACAC;padding: 0 0 4px;width:100%;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.productSelItem_name{width:100%;font-weight: 700;font-size: 13px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap}
.productSelItem_price{margin: 8px 0 0;color: #5E5E5E;font-weight: 500;font-size: 13px}
.productSelItem_infoCount{background: rgba(58, 58, 58, 0.6);padding: 3px;font-weight: 700;font-size: 12px;color: #fff;text-align: center;position: absolute;bottom: 0;left: 0;width: 100%;backdrop-filter: blur(2px);-webkit-backdrop-filter: blur(2px)}

.chkSelItemBox + .chkSelItemBox{border-top: 1px solid #f6f6f6}

.chkSelItemBox label{padding: 13px 34px 13px 0;display: block;background: url(/assets/images/basic/radio_off.svg) no-repeat right center;background-size: 24px}
.chkSelItemBox input:checked + label{background-image: url(/assets/images/basic/radio_on.svg)}
.selPopupBtnBox{background: #fff;padding: 20px}
.productSelInfo{background: #F6F6F6;border: 1px solid #E7E7E7;border-radius: 15px;padding: 14px;margin: 0 0 10px}

.cancelPriceItem{margin: 20px 0 0}
.cancelPriceItem .order_infoItem{padding: 10px 0}

/*반응형*/
@media (max-height:420px){
    .joinPage{align-items: flex-start}
}
@media (max-width:1440px){
    
}
@media (max-width:1366px){
    
}
@media (max-width:1280px){
    
}
@media (max-width:1152px){
    
}
@media (max-width:1024px){
    
}
@media (max-width:960px){

}
@media (max-width: 720px){

}
@media (max-width: 650px){

}
@media (max-width: 550px){

}
@media (max-width: 450px){

}
@media (max-width: 400px){
}
@media (max-width: 340px){

}
/*//*/